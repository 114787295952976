var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("section", {
    staticClass: "no-data"
  }, [_c("img", {
    attrs: {
      src: _vm.image,
      alt: _vm.description
    }
  }), _c("div", [_vm._v(_vm._s(_vm.description))])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };