import noData from '@/assets/images/no_data.png';
export default {
  name: 'NoData',
  props: {
    description: {
      type: String,
      default() {
        return '暂无数据';
      }
    },
    image: {
      type: String,
      default: noData
    }
  }
};