var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "payslip-header",
    style: {
      backgroundImage: "url(" + _vm.bgImg + ")"
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };